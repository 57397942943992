import { Button, Flex, FormControl, Input, Text, VStack } from '@chakra-ui/react';
import { FormEvent, useCallback, useMemo, useState } from 'react';

import { backendUrl } from 'helpers/environment';

type ExternalOrgIdResponse = {
  error: string | null;
  externalSsoOrgId: string | null;
};

interface Props {
  ssoLoginCallback: (externalOrgId: string) => void;
}
const SSOForm = ({ ssoLoginCallback }: Props) => {
  const [slug, setSlug] = useState<string>('');
  const [ssoError, setSsoError] = useState<string | null>(null);

  const isEmptySlug = useMemo(() => slug.length === 0, [slug]);

  const onSlugChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
  }, []);

  const initiateFirebaseSSOFlow = useCallback(() => {
    const makeRequest = async (orgSlug: string) => {
      try {
        setSsoError(null);
        const res = await fetch(`${backendUrl}/auth/sso/${orgSlug}/externalOrgID`);

        const body = (await res.json()) as ExternalOrgIdResponse;
        if (!res.ok) {
          if (body.error != null) {
            throw new Error(body.error);
          }
        }
        const externalSsoOrgId = body.externalSsoOrgId;
        if (externalSsoOrgId == null) {
          throw new Error('externalSsoOrgId is null');
        }
        ssoLoginCallback(externalSsoOrgId);
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : 'Unknown error';
        setSsoError(errMsg);
        console.error('initiateFirebaseSSOFlow', error);
      }
    };
    makeRequest(slug);
  }, [slug, ssoLoginCallback]);

  const onFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!isEmptySlug) {
        initiateFirebaseSSOFlow();
      }
    },
    [initiateFirebaseSSOFlow, isEmptySlug],
  );

  return (
    <form onSubmit={onFormSubmit}>
      <VStack gap={4} w="full">
        <Flex>
          <Flex
            gap="4px"
            alignItems="center"
            bg="rgba(180, 175, 175, 0.1)"
            borderRadius={16}
            height="48px"
            p={2}
            pl={4}
          >
            <Text color="runway.white">app.runway.com/</Text>
            <FormControl w="full">
              <Input
                autoFocus
                value={slug}
                spellCheck={false}
                placeholder="org-url"
                type="text"
                fontSize="16px"
                fontWeight="medium"
                color="white"
                height="32px"
                p={2}
                alignSelf="stretch"
                bg="rgba(25, 34, 39, 0.60)"
                border="1px solid"
                borderColor="rgba(180, 175, 175, 0.10)"
                borderRadius="10px"
                _hover={{ outline: 'none' }}
                _focusVisible={{
                  outline: 'none',
                }}
                onChange={onSlugChange}
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex flexDir="column" gap={4} w="full">
          <Button
            isDisabled={isEmptySlug}
            w="full"
            borderRadius="16px"
            bgColor="runway.yellow"
            color="black"
            border="none"
            _hover={{ bgColor: 'runway', opacity: 0.8 }}
            h="48px"
            onClick={initiateFirebaseSSOFlow}
          >
            Continue
          </Button>
          {ssoError != null && (
            <Text color="runway.red" fontSize="sm" fontWeight="medium" textAlign="center">
              {ssoError}
            </Text>
          )}
        </Flex>
      </VStack>
    </form>
  );
};

export default SSOForm;

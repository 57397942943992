import * as Sentry from '@sentry/nextjs';
import { useCallback } from 'react';

import { backendUrl } from 'helpers/environment';

export function useTriggerRipplingSSO() {
  const startRipplingSSO = useCallback(() => {
    window
      .fetch(`${backendUrl}/auth/getRipplingSSO`)
      .then((resp) => {
        resp.json().then((data: { url: string }) => {
          window.location.href = data.url;
        });
      })
      .catch((e: Error) => {
        Sentry.addBreadcrumb({
          message: 'Rippling start SSO flow error',
          data: { e, message: e.message },
        });
        console.error('Rippling start SSO flow error', e.message);
      });
  }, []);

  return startRipplingSSO;
}

import * as Sentry from '@sentry/nextjs';
import { getAuth, signOut } from 'firebase/auth';

import { LogoutDocument, LogoutMutation, LogoutMutationVariables } from 'generated/graphql';
import { clearRestorableState } from 'helpers/localState';
import { loggedOut } from 'reduxStore/reducers/loginSlice';
import { AsyncAppThunk } from 'reduxStore/store';

export const logOutUser = (reason?: string): AsyncAppThunk => {
  return async (dispatch, _getState, { urqlClient }) => {
    await Promise.all([
      signOut(getAuth()),
      urqlClient.mutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, {}).toPromise(),
    ]);
    dispatch(loggedOut());
    Sentry.setUser(null);
    clearRestorableState();
    const reasonEscaped = reason != null ? encodeURIComponent(reason) : null;
    window.location.href = `/login${reasonEscaped != null ? `?logoutReason=${reasonEscaped}` : ''}`;
  };
};

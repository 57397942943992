import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import RunwayCurvesBackdrop from 'components/RunwayCurvesBackdrop/RunwayCurvesBackdrop';
import { SquircleRunwayLogo } from 'components/RunwayLogo/RunwayLogo';

interface Props {
  children: React.ReactNode;
  errorContent?: React.ReactNode;
  includeTOS: boolean;
}

const LoggedOutModal: React.FC<Props> = ({ children, errorContent, includeTOS }) => {
  return (
    <RunwayCurvesBackdrop>
      <VStack gap={8} position="relative">
        {errorContent != null && (
          <Box w="29rem" p={4} mt={14} bg="runway.red" borderRadius={50} textAlign="center">
            <Text fontSize="sm" color="runway.white" fontWeight="semibold">
              {errorContent}
            </Text>
          </Box>
        )}
        <VStack
          w="29rem"
          boxShadow="0 0 125px 0 rgba(0, 0, 0, 0.20)"
          p={16}
          bg="surface"
          borderRadius={50}
          gap={12}
          background="linear-gradient(31deg, rgba(44, 50, 53, 0.30) 0%, rgba(25, 34, 38, 0.30) 100%)"
          backdropFilter="blur(30px)"
          position="relative"
        >
          <SquircleRunwayLogo />
          {children}
        </VStack>
        {includeTOS && (
          <Text
            textAlign="center"
            w="24.5rem"
            color="runway.white"
            opacity={0.3}
            fontWeight="medium"
            fontSize="xxs"
          >
            By clicking “Continue” above, you acknowledge that you have read, understood, and agree
            to Runway&apos;s{' '}
            <a target="_blank" href="https://runway.com/terms" rel="noreferrer">
              <u>Terms</u>
            </a>{' '}
            and{' '}
            <a target="_blank" href="https://runway.com/privacy" rel="noreferrer">
              <u>Privacy Policy</u>
            </a>
            .
          </Text>
        )}
      </VStack>
    </RunwayCurvesBackdrop>
  );
};

export default LoggedOutModal;

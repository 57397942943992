import { OAuthProvider } from 'firebase/auth';

// Xero
const xeroAuthProvider = new OAuthProvider('oidc.xero');
xeroAuthProvider.addScope('openid');
xeroAuthProvider.addScope('profile');
xeroAuthProvider.addScope('email');

// SSOReady
const ssoReadyAuthProvider = new OAuthProvider('oidc.ssoready');
ssoReadyAuthProvider.addScope('openid');
ssoReadyAuthProvider.addScope('profile');
ssoReadyAuthProvider.addScope('email');

export { ssoReadyAuthProvider, xeroAuthProvider };

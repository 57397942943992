import { Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const MESSAGES = [
  'Fetching financial figures',
  'Reviewing revenue reports',
  'Preparing planning projections',
  'Reticulating splines',
  'Collating cashflow calculations',
  <>
    Not loading? Check our{' '}
    <Link href="https://status.runway.com" isExternal color="gray.600">
      status page
    </Link>{' '}
    or{' '}
    <Link href="mailto:support@runway.com" isExternal color="gray.600">
      contact support
    </Link>{' '}
    for help.
  </>,
];

const INITAL_DELAY_MS = 10000;
const MESSAGES_INTERVAL_MS = 7000;

const LoadingPage: React.FC = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number | null>(null);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isLogoVisible, setIsLogoVisible] = useState(false);

  useEffect(() => {
    // Trigger the logo fade-in immediately on component mount
    setTimeout(() => {
      requestAnimationFrame(() => {
        setIsLogoVisible(true);
      });
    }, 420);

    const initialTimer = setTimeout(() => {
      setCurrentMessageIndex(0);
      setTimeout(() => {
        setIsMessageVisible(true); // Fade in the first message
      }, 100);
    }, INITAL_DELAY_MS);

    return () => clearTimeout(initialTimer);
  }, []);

  useEffect(() => {
    if (currentMessageIndex != null) {
      const messageInterval = setInterval(() => {
        setIsMessageVisible(false);
        setTimeout(() => {
          if (currentMessageIndex != null && currentMessageIndex + 1 < MESSAGES.length) {
            setCurrentMessageIndex(currentMessageIndex + 1);
          }
          setIsMessageVisible(true);
        }, 1000);
      }, MESSAGES_INTERVAL_MS);

      return () => clearInterval(messageInterval);
    }

    return undefined;
  }, [currentMessageIndex]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="electron-drag"
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          style={{
            opacity: isLogoVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <Image src="/loader.webp" alt="Loading" width="48px" height="48px" marginBottom={4} />
        </div>
        <Text
          fontSize="xxs"
          fontWeight="medium"
          color="#8A9499"
          style={{
            opacity: isMessageVisible ? 1 : 0,
            height: '1rem',
            transition: 'opacity 0.6s ease-in-out',
          }}
        >
          {MESSAGES[currentMessageIndex ?? 0]}
        </Text>
      </div>
    </div>
  );
};

export default React.memo(LoadingPage);
